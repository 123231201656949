@font-face {
  font-family: calibril;
  src: url(./fonts/calibril.ttf) format("truetype");
}

@font-face {
  font-family: InkFree;
  font-style: normal;
  font-weight: normal;
  src: url(./fonts/inkfree.woff) format("woff");
}

@font-face {
  font-family: Marion;
  src: url(./fonts/marion.ttf) format("truetype");
}

@font-face {
  font-family: Bodoni;
  src: url(./fonts/bodonixt.ttf) format("truetype");
}

html,
body,
#app,
#app > div {
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100vh;
  /* overflow-y: hidden; */
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #efefef;
  border-radius: 2px;
}
/* Handle */
.button-list-container::-webkit-scrollbar-thumb {
  background: #6b7f93;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#outer {
  display: flex;
  flex-flow: column;
}

/* buttons */
.boton {
  border-radius: 3px;
  background: #47a9ff;
  border-style: none;
  padding: 10px 10px;
  color: white;
}

.boton:disabled {
  background: #bbdefd;
}

.boton:hover:enabled,
.boton:active:enabled {
  background: #006cd4;
}

.fondo {
  background: url("../src/components/InicioSecion/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-grow: 1;
}
.material-container {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
}

.btn-wrapper {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 1.1rem;
  height: 50px;
  line-height: 50px;
  width: 150px;
  background: #007bff;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

.btn-back {
  color: #f5f5f5;
}

.btn-back:hover {
  color: #f5f5f5;
  text-decoration: none;
}
.btn-wrapper:hover {
  background: #0d47a1;
  transition-duration: 0.4s;
}

/* card doc stuff */
.docs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.cardDoc {
  max-width: 258px;
  width: 30%;
  height: 360px;
  border-radius: 15px;
  padding: 1.5rem;
  margin: 0.8rem;
  background: white;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}
.docs.no-material-container {
  min-height: 300px;
}
.no-material {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  font-size: 1.6rem;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

.cardDoc:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: 0.5s;
  opacity: 1;
}
.cardDoc img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
}
.cardDoc .info {
  position: relative;
  z-index: 3;
  color: white;
  opacity: 1;
  transition: 0.5s;
}
.cardDoc .info h1 {
  margin: 0px;
  font-size: 2rem;
}
.cardDoc .info p {
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
}
.cardDoc .info button {
  padding: 0.6rem;
  outline: none;
  border: none;
  border-radius: 3px;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease;
}
.cardDoc .info button:hover {
  text-decoration: none;
}

.cardDoc .info button.small {
  height: 30px;
}

@media (max-width: 900px) {
  .cardDoc {
    width: 80%;
  }
  .material-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (max-width: 800px) {
  .cardDoc {
    width: 80%;
  }
  .material-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .btn-wrapper {
    position: absolute;
    left: 0;
  }
  .material-name {
    margin-left: 50px;
  }
}

@media (max-width: 750px) {
  .cardDoc {
    width: 60%;
  }
  .material-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
  .btn-wrapper {
    position: inherit;
    left: 0px;
    font-size: 1.2rem;
    width: 90%;
    margin-bottom: 5px;
  }
  .material-name {
    margin-left: 30px;
  }
}

@media (max-width: 470px) {
  .cardDoc {
    width: 100%;
  }
  .material-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
  .btn-wrapper {
    position: inherit;
    font-size: 1.2rem;
    width: 100%;
    margin-bottom: 5px;
  }

  .no-material {
    text-align: center;
  }
}
