.loading{
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    /* justify-content: center; */
    align-items: center;

    background: #F5F5F5; 
}

.loader{
    /* border: 2px solid #000; */
    background: #FFF;
    border-radius: 8px;
    padding: 16px;
    margin-top: 32px; 
    -webkit-box-shadow: 5px 5px 13px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 13px -5px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 13px -5px rgba(0,0,0,0.75);
}

.loader h1{
    color: rgb(184, 184, 184);
    text-align: center;
    font-size: 32px;
}

.flor{
    height: 250px;
    width: 250px;
    margin-top: 32px;
    margin-bottom: 32px;
    background: linear-gradient(135.06deg, #D321FF -17.03%, #8AF9C3 100%);
    -webkit-mask: url(./florvida.svg) center;
    mask: url(./florvida.svg) center;
    mask-size: cover;
    -webkit-mask-size: cover;
    animation: giro 20s linear infinite;
}

@keyframes giro{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}